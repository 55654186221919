import { render, staticRenderFns } from "./RuleList.vue?vue&type=template&id=38ee091b&scoped=true&"
import script from "./RuleList.vue?vue&type=script&lang=js&"
export * from "./RuleList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ee091b",
  null
  
)

export default component.exports