<template>
  <div class="goodsList container">
    <div class="searchbox">
      <el-button type="primary"
                 @click="toAddRule">新增规则</el-button>
    </div>
    <div class="table-box">
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }">
        <el-table-column label="创建时间"
                         prop="create_time"></el-table-column>
        <el-table-column label="赠送优惠券">
          <template slot-scope="scope">
            <p v-for="item in scope.row.item"
               :key="item.coupon_data_id">
              <span>{{ item.coupon_name }}</span>
              <span style="margin-left: 10px">{{ item.messages }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="num"
                         label="每种自动发放张数"></el-table-column>
        <el-table-column label="发放优先级"
                         width="200"
                         :render-header="renderHeader">
          <template slot-scope="scope">
            <el-button type="text"
                       size="medium"
                       @click="sortRule(scope.row.rule_id, 1)">置顶</el-button>
            <el-button type="text"
                       size="medium"
                       @click="sortRule(scope.row.rule_id, 2)">上移</el-button>
            <el-button type="text"
                       size="medium"
                       @click="sortRule(scope.row.rule_id, 3)">下移</el-button>
            <el-button type="text"
                       size="medium"
                       @click="sortRule(scope.row.rule_id, 4)">置底</el-button>
          </template>
        </el-table-column>
        <el-table-column label="是否开启赠送商品条件"
                         width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_limit == 1">开启</span>
            <span v-if="scope.row.goods_limit == 0">不开启</span>
          </template>
        </el-table-column>
        <el-table-column label="启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       @change="openOffShopGiveCoupon($event, scope.row.rule_id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="180">
          <template slot-scope="scope">
            <el-button type="text"
                       size="medium"
                       @click="toEditRule(scope.row.rule_id)"
                       :disabled='scope.row.status==1'>编辑</el-button>
            <el-button type="text"
                       size="medium"
                       class="red"
                       @click="delRule(scope.row.rule_id)"
                       :disabled='scope.row.status==1'>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="page"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="10"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total"
                   style="margin: 30px 0 40px 0"></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'goodsList',
  data() {
    return {
      total: 0,
      page: 1,
      pageNumber: 10,
      tableData: [],
      module_id_array: window.utils.storage.getter('module_id_array') || []
    };
  },
  components: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let data = {
        page: this.page,
        pageNumber: this.pageNumber
      };
      this.axios
        .post('/store/Shopcoupon/shopGiveCouponLists', data)
        .then((res) => {
          this.total = res.data.total;
          let list = res.data.list;
          list.forEach((ele) => {
            ele.create_time = this.dayjs
              .unix(ele.create_time)
              .format('YYYY-MM-DD HH:mm');
            ele.status = ele.status == 1 ? true : false;
          });
          this.tableData = list;
        });
    },

    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    toAddRule() {
      this.$router.push('./addRule');
    },
    //启用禁用
    openOffShopGiveCoupon(value, rule_id) {
      let data = {
        rule_id,
        status: value ? 1 : 0
      };
      this.axios
        .post('/store/Shopcoupon/openOffShopGiveCoupon', data)
        .then(() => {
          this.getList();
        });
    },
    //修改排序
    sortRule(id, type) {
      let data = {
        rule_id: id,
        sort: type
      };
      this.axios
        .post('/store/Shopcoupon/editShopGiveCouponSort', data)
        .then((res) => {
          this.$message.success('修改成功');
          this.getList();
        });
    },
    //修改状态
    updateCouponStatus(item) {
      let data = {
        coupon_data_id: item.coupon_data_id,
        coupon_status: item.coupon_status == 1 ? 0 : 1
      };
      this.axios
        .post('/store/Shopcoupon/updateCouponStatus', data)
        .then((res) => {
          this.getList();
          this.$message.success('删除成功');
        });
    },
    delRule(rule_id) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          rule_id
        };
        this.axios
          .post('/store/Shopcoupon/delShopGiveCoupon', data)
          .then(() => {
            this.getList();
          });
      });
    },
    toEditRule(rule_id) {
      this.$router.push({
        path: '/operation/editRule',
        query: {
          rule_id
        }
      });
    },
    renderHeader(h, { column, $index }) {
      return (
        <div class="flex align-center">
          <div style="margin-right:5px">发放优先级</div>
          <el-tooltip
            class="item"
            effect="dark"
            content="一次购买行为只送一个规则的优惠券，触发多个规则时，送优先级最高的。"
            placement="top-start"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      );
    }
  }
};
</script>

<style lang='scss' scoped>
</style>
